<template>
  <el-dialog title="配送单详情" :visible.sync="dialogVisible" append-to-body custom-class="dialog" :before-close="handleCancel">
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="handleCancel">关闭</el-button>
    </span>
    <div class="container" v-loading="loading">
      <div class="itemContent">
        <div class="item1" style="margin-right:20px">
          <div class="title">配送通道</div>
          <div class="content">{{ordFreightDetail?.sPlatform}}</div>
        </div>
        <div class="item1">
          <div class="title">运单号</div>
          <div class="content">{{ordFreightDetail?.cSys}}</div>
        </div>
      </div>
      <div class="itemContent">
        <div class="item1" style="margin-right:20px">
          <div class="title">骑手姓名</div>
          <div class="content">{{ordFreightDetail?.sRider}}</div>
        </div>
        <div class="item1">
          <div class="title">手机号码</div>
          <div class="content blue">{{ordFreightDetail?.cRiderPhone}}</div>
        </div>
      </div>
      <div class="itemContent">
        <div class="item1" style="margin-right:20px">
          <div class="title">配送费</div>
          <div class="content">￥{{ordFreightDetail?.nDelivery}}</div>
        </div>
        <div class="item1">
          <div class="title">运单调度费</div>
          <div class="content">￥{{ordFreightDetail?.nDispatch}}</div>
        </div>
      </div>
      <div class="itemContent">
        <div class="item1" style="margin-right:20px">
          <div class="title">配送合计</div>
          <div class="content">￥{{ordFreightDetail?.nTotal}}</div>
        </div>
        <div class="item1">
          <div class="title">配送状态</div>
          <div class="content blue">{{freightStatusMap[ordFreightDetail?.bStatus??1]}}</div>
        </div>
      </div>
      <div class="itemContent" style="margin-top: 20px">
        <div class="item1" style="margin-right:20px">
          <div class="title">订单渠道</div>
          <div class="content">{{ordFreightDetail?.sChannel}}</div>
        </div>
        <div class="item1">
          <div class="title">订单号</div>
          <div class="content blue">{{ordFreightDetail?.cOrderSys}}</div>
        </div>
      </div>
      <div class="itemContent">
        <div class="item1" style="margin-right:20px">
          <div class="title">店铺名称</div>
          <div class="content">{{ordFreightDetail?.sShop}}</div>
        </div>
        <div class="item1">
          <div class="title">配送门店</div>
          <div class="content blue">{{ordFreightDetail?.sStore}}</div>
        </div>
      </div>
      <div class="itemContent">
        <div class="item1" style="margin-right:20px">
          <div class="title">收货人</div>
          <div class="content">{{ordFreightDetail?.cCustomerName}}</div>
        </div>
        <div class="item1">
          <div class="title">隐私号码</div>
          <div class="content blue">{{ordFreightDetail?.cCustomerPhonePrivate}}</div>
        </div>
      </div>
      <div class="itemContent">
        <div class="item">
          <div class="title">收货地址</div>
          <div class="content blue">{{ordFreightDetail?.cCustomerAddr}}</div>
        </div>
      </div>
      <div class="itemContent">
        <div class="item">
          <div class="title">商家备注</div>
          <div class="content">{{ordFreightDetail?.cRemark}}</div>
        </div>
      </div>
      <div class="itemContent" style="margin-top:20px">
        <div class="item1" style="margin-right:20px">
          <div class="title">下单时间</div>
          <div class="content">{{ordFreightDetail?.tCreate}}</div>
        </div>
        <div class="item1">
          <div class="title">接单时间</div>
          <div class="content">{{ordFreightDetail?.tReceive}}</div>
        </div>
      </div>
      <div class="itemContent">
        <div class="item1" style="margin-right:20px">
          <div class="title">到店时间</div>
          <div class="content">{{ordFreightDetail?.tArrive}}</div>
        </div>
        <div class="item1">
          <div class="title">完成时间</div>
          <div class="content blue">{{ordFreightDetail?.tComplete}}</div>
        </div>
      </div>
      <div class="itemContent" style="margin-top:20px">
        <div class="item2" style="margin-right:20px">
          <div class="title">接单时长</div>
          <div class="content">{{ordFreightDetail?.fReceive}}</div>
        </div>
        <div class="item2" style="margin-right:20px">
          <div class="title">到店时长</div>
          <div class="content">{{ordFreightDetail?.fArrive}}</div>
        </div>
        <div class="item2">
          <div class="title">配送时长</div>
          <div class="content">{{ordFreightDetail?.fDelivery}}</div>
        </div>
      </div>
      <div class="itemContent">
        <div class="item2" style="margin-right:20px">
          <div class="title">配送距离</div>
          <div class="content">{{ordFreightDetail?.nDistance}}</div>
        </div>
        <div class="item2" style="margin-right:20px">
          <div class="title">是否超时</div>
          <div class="content">{{timeoutMap[ordFreightDetail?.bTimeout??0]}}</div>
        </div>
        <div class="item2">
          <div class="title">超时时长</div>
          <div class="content">{{ordFreightDetail?.fTimeout}}</div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import {freightStatusMap,timeoutMap} from "@/utils/deliveryMap";
import {getOrdFreightDetail} from "@/api/delivery";
import {formatTime} from "@/utils/utils";
export default {
  name: "ViewOrdFreightDetail",
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    sid: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      loading:false,
      freightStatusMap:freightStatusMap,
      timeoutMap:timeoutMap,
      dialogVisible: this.visible,
      ordFreightDetail: {
      },
    };
  },
  created() {

  },
  mounted() {
  },
  methods: {
    getOrdFreightDetail() {
      if(this.sid){
        this.loading = true;
        getOrdFreightDetail(this.sid).then(result => {
          if (result) {
            this.ordFreightDetail = result.data;
            this.loading = false;
          }
        });
      }
    },
    dateFormatter(value) {
      return value?formatTime(value,"YYYY-MM-DD HH:mm"):'';
    },
    handleCancel() {
      this.ordFreightDetail = {};
      this.dialogVisible = false;
    },
  },
  watch: {
    visible(newVal) {
      if(newVal){
        this.getOrdFreightDetail();
      }
      this.dialogVisible = newVal;
    },
    dialogVisible(newVal) {
      this.$emit('update:visible', newVal);
    }
  },
};
</script>

<style lang="scss"  scoped>
::v-deep .dialog{
  width: 750px!important;
}
::v-deep .el-dialog__body{
  padding: 10px 20px 30px 20px;
}
::v-deep .el-dialog {
  margin-top: 1vh!important;
}
.container{
  width: 100%;
  height: 100%;
  padding-bottom: 30px;
  .itemContent{
    width: 100%;
    height: auto;
    min-height: 40px;
    overflow: hidden;
    .blue{
      color:#409EFF;
    }
    .item1{
      width: calc(50% - 12px);
      height: auto;
      min-height: 40px;
      float: left;
      .title{
        width: 80px;
        height: auto;
        min-height: 40px;
        line-height: 40px;
        float: left;
        text-align: left;
        color:#86909C;
      }
      .content{
        width: calc(100% - 80px);
        height: auto;
        min-height: 40px;
        line-height: 40px;
        float: left;
        text-align: right;
      }
    }
    .item2{
      width: calc(33% - 15px);
      height: auto;
      min-height: 40px;
      float: left;
      .title{
        width: 80px;
        height: auto;
        min-height: 40px;
        line-height: 40px;
        float: left;
        text-align: left;
        color:#86909C;
      }
      .content{
        width: calc(100% - 80px);
        height: auto;
        min-height: 40px;
        line-height: 40px;
        float: left;
        text-align: right;
      }
    }
  }
  .item{
    width: 100%;
    height: auto;
    min-height: 40px;
    .title{
      width: 80px;
      height: auto;
      min-height: 40px;
      line-height: 40px;
      float: left;
      text-align: left;
      color:#86909C;
    }
    .content{
      width: calc(100% - 100px);
      height: auto;
      min-height: 40px;
      line-height: 40px;
      float: left;
      text-align: right;
    }
  }
}
</style>
